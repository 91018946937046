import Link from 'next/link'
import Image from 'next/image'
import cx from 'classnames'

import { Typography } from 'components/typography'
// @ts-expect-error
import { ReactComponent as PaginationCircle } from '/public/svgs/pagination-circle.svg'

import { block } from 'utils/bem'

import styles from './image-link.module.scss'

type ImageLinkProps = {
  text: string
  linkTo: string
  imgURL: string
  className?: string
  disabled?: boolean
  projectTitle?: boolean
  hoverLink?: boolean
  mouseEnter: () => void
  mouseLeave: () => void
}

export const ImageLink = ({
  text,
  linkTo,
  imgURL,
  disabled,
  className,
  projectTitle,
  mouseEnter,
  mouseLeave,
  hoverLink,
}: ImageLinkProps) => {
  return (
    <div
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={block(
        styles,
        'link',
        [disabled && 'disabled', hoverLink && 'hoverLink'],
        className
      )}
    >
      <Link href={linkTo}>
        <a>
          <Image src={imgURL} width={39} height={39} alt="" />
          <PaginationCircle className={styles.circle} />
        </a>
      </Link>
      <Typography
        variant="button-small"
        className={cx(styles.text, projectTitle && styles.text__title)}
      >
        {text}
      </Typography>
    </div>
  )
}
