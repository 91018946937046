import * as React from 'react'

import type { NextPage } from 'next'

import cx from 'classnames'
import Image from 'next/image'
import Link from 'next/link'

import { FadeIn } from 'components/animations'
import { Footer } from 'components/footer'
import { HeadWrapper } from 'components/head-wrapper'
import { ImageLink } from 'components/image-link'
import { InfoBox, Overlay, SeekerButton } from 'components/info-box'
import { Loader } from 'components/loader'
import { NavBar } from 'components/nav-bar'
import { Typography } from 'components/typography'
// import { MintOverlay } from 'components/minting/overlay'

import { useMediaQuery } from 'utils/hooks/use-media-query'
// import { trpc } from 'utils/trpc'

import styles from './home.module.scss'
// import { mintable } from 'utils/mint'

type HoveredStates = 'observatory' | 'news' | 'bookshelf' | null

const Home: NextPage = () => {
  const isMobile = useMediaQuery('(max-width: 900px)')

  const [isSafari, setIsSafari] = React.useState(false)

  const [hovered, setHovered] = React.useState<HoveredStates>(null)

  const [hoveredBox, setHoveredBox] = React.useState<HoveredStates>(null)

  const [seekerInfo, setSeekerInfo] = React.useState(false)

  const [loading, setLoading] = React.useState(true)

  const showHover = (element: HoveredStates) => {
    setHovered(element)
  }

  const showInfoBox = () => {
    return setSeekerInfo(!seekerInfo)
  }

  const closeInfoBox = () => {
    localStorage.setItem('visited', JSON.stringify(true))
    setSeekerInfo(false)
  }

  //this is a very hacky solution to assets loading issue on page.. please investigate a better solution
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 800)
    return () => clearTimeout(timer)
  }, [])

  React.useEffect(() => {
    if (!isMobile) {
      const visited = JSON.parse(localStorage.getItem('visited') || 'false')

      if (!visited) {
        return setSeekerInfo(true)
      }
    }

    return setSeekerInfo(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const detectSafari =
      //@ts-ignore
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]'
      })(
        //@ts-ignore
        !window['safari'] ||
          //@ts-ignore
          (typeof safari !== 'undefined' && safari.pushNotification)
      )

    if (detectSafari === true) {
      setIsSafari(true)
    }
  }, [])
  return (
    <>
      <div className={cx('loader', !loading ? 'hideLoader' : null)}>
        <Loader />
      </div>
      <HeadWrapper
        title="Project Tempus | Metaverse Comics of Wonder"
        metaTitle="Seamlessly traverse through the imagined stories of the Metaverse"
        description="Project Tempus is a web3 comic reading platform that allows you to seamlessly traverse through the imagined stories of the metaverse with digital ownership. Aiming to enhance and enrich the stories of the Metaverse in a captivating and immersive manner."
        pageUrl="https://projecttempus.xyz"
        imageUrl="https://projecttempus.xyz/previews/homepage.png"
      />

      <>
        <div className={styles.foyer}>
          <Overlay toggle={seekerInfo} onClose={closeInfoBox} />
          <SeekerButton showHide={showInfoBox} />

          <NavBar className={styles.foyerNav} />

          <FadeIn className={styles.border}>
            {isSafari ? (
              <Image
                src="/background/foyer-poster-v5.png"
                layout="fill"
                alt=""
                objectFit="cover"
                objectPosition="center"
                quality={100}
                priority={true}
              />
            ) : (
              <video
                autoPlay
                loop
                muted
                poster="/background/foyer-poster-v5.png"
                playsInline
              >
                <source
                  src={'/background/foyer-light-v5.mp4'}
                  type="video/mp4"
                />
              </video>
            )}
            {!isMobile && (
              <div className={styles.boardContainer}>
                <Link href={'/news'}>
                  {/*eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  <a
                    onMouseEnter={() => setHoveredBox('news')}
                    onMouseLeave={() => setHoveredBox(null)}
                    className={cx(
                      styles.hoverBox,
                      hovered === 'news' && styles.hoverBox__hovered
                    )}
                    aria-label="go to news"
                  ></a>
                </Link>
                <Image
                  src={'/background/board-hover.png'}
                  alt="bulletin-board"
                  className={styles.boardBackground}
                  layout="fill"
                />

                <div>
                  <Link href={'/project/machines-of-mana'}>
                    {/*eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a
                      className={cx(
                        styles.observatoryHoverBox,
                        hovered === 'observatory' &&
                          styles.observatoryHoverBox__hovered
                      )}
                      onMouseEnter={() => setHoveredBox('observatory')}
                      onMouseLeave={() => setHoveredBox(null)}
                      aria-label="go to project tempus"
                    ></a>
                  </Link>
                  <Image
                    src={'/background/window-hover.png'}
                    alt="observatory door"
                    className={`${styles.boardBackground}`}
                    layout="fill"
                  />
                </div>
                <div>
                  <Link href={'/bookshelf'}>
                    {/*eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a
                      onMouseEnter={() => setHoveredBox('bookshelf')}
                      onMouseLeave={() => setHoveredBox(null)}
                      className={cx(
                        styles.bookshelfHoverBox,
                        hovered === 'bookshelf' &&
                          styles.bookshelfHoverBox__hovered
                      )}
                      aria-label="go to bookshelf"
                    ></a>
                  </Link>
                  <Image
                    src={'/background/bookshelf-hover.png'}
                    alt="bookshelf"
                    className={styles.boardBackground}
                    layout="fill"
                  />
                </div>
              </div>
            )}
            <nav aria-label="secondary" className={styles.linkContainer}>
              <ImageLink
                imgURL="/link/circle-news.png"
                text="NEWS"
                linkTo="/news"
                className={styles.news}
                mouseEnter={() => showHover('news')}
                mouseLeave={() => setHovered(null)}
                hoverLink={hoveredBox === 'news'}
              />
              <ImageLink
                imgURL="/link/circle-observatory.png"
                text="MACHINES OF MANA"
                linkTo="/project/machines-of-mana"
                projectTitle
                mouseEnter={() => showHover('observatory')}
                mouseLeave={() => setHovered(null)}
                hoverLink={hoveredBox === 'observatory'}
              />
              <ImageLink
                imgURL="/link/circle-bookshelf.png"
                text="BOOKSHELF"
                linkTo="/bookshelf"
                mouseEnter={() => showHover('bookshelf')}
                mouseLeave={() => setHovered(null)}
                hoverLink={hoveredBox === 'bookshelf'}
              />
            </nav>
          </FadeIn>
          <Minting />
        </div>
        <Footer fixed />

        <InfoBox
          toggle={seekerInfo}
          onClose={closeInfoBox}
          // isMobile={isMobile}
        >
          <Typography variant="body-regular-book" noMargins>
            Greetings, I am F.R.N.D., and I will be your guide, helping you
            navigate the wondrous Grand Archives.
          </Typography>
          <Typography variant="body-regular-book" noMargins>
            You are currently in the Foyer, the passageway that links the
            illustrious rooms you will inevitably wander through. If you wish to
            uncover new and untold stories, please head through the{' '}
            <Link href={'/project/machines-of-mana'}>
              <a className="link">golden archway</a>
            </Link>
            . If you wish to revisit a story, please head to the{' '}
            <Link href={'/bookshelf'}>
              <a className="link">bookshelf</a>
            </Link>
            .
          </Typography>
        </InfoBox>
      </>
    </>
  )
}

/**
 * Minting is changing this will be comented out until next book release, where
 * new minting overlay might be different
 */
const Minting = () => {
  // const { data: book } = trpc.useQuery([
  //   'project.book',
  //   { bookSlug: 'age-of-the-starstone', projectSlug: 'machines-of-mana' },
  // ])

  // if (
  //   book
  // ) {
  //   return (
  //     <MintOverlay
  //       bookId={book.id}
  //       firstEditionCollectionId={book.firstEditionCollectionId!}
  //       firstEditionDurationInDays={book.firstEditionDurationInDays!}
  //       mintStartDate={book.mintStartDate!}
  //       secondEditionCollectionId={book.secondEditionCollectionId!}
  //       title={book.title}
  //       mintCost={book.cost}
  //       slug="age-of-the-starstone"
  //     />
  //   )
  // }

  return null
}

export default Home
