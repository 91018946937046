import type { WithChildren } from 'types'
import Image from 'next/image'

import cx from 'classnames'

import styles from './info-box.module.scss'

type InfoBoxProps = WithChildren<{
  className?: string
  toggle: boolean
  onClose: () => void
  positionLeft?: boolean
  // isMobile: boolean
}>

export const InfoBox = ({
  className,
  toggle,
  onClose,
  children,
  positionLeft,
}: // isMobile,
InfoBoxProps) => {
  return (
    <article
      className={cx(
        styles.seekerInfoBox,
        toggle && styles.seekerInfoBox__open,
        positionLeft && styles.seekerInfoBox__left,
        className
      )}
    >
      <div className={styles.tiny}>
        <Image
          src={'/images/tiny-teal-seeker.png'}
          width={32}
          height={32}
          alt={'seeker'}
        />
      </div>
      <div>{children}</div>
      <div>
        <button aria-label="close" onClick={onClose}>
          <svg width="24" height="24" aria-hidden="true">
            <use
              href="/svgs/icon-sprites.svg#close"
              xlinkHref="/svgs/icon-sprites.svg#close"
            />
          </svg>
        </button>
      </div>
    </article>
  )
}

export const SeekerButton = ({
  showHide,
  positionLeft,
}: {
  showHide: () => void
  positionLeft?: boolean
}) => {
  return (
    <button
      className={cx(
        styles.seekerButton,
        positionLeft && styles.seekerButton__left
      )}
      onClick={showHide}
    >
      <Image
        src={'/images/big-seeker.png'}
        alt="seeker"
        width={128}
        height={118}
        className={styles.seekerButton__image}
      />
    </button>
  )
}

export const Overlay = ({
  toggle,
  onClose,
}: {
  toggle: boolean
  onClose: () => void
}) => {
  return (
    <div
      className={cx(styles.overlay, toggle && styles.overlay__show)}
      onClick={onClose}
    />
  )
}
