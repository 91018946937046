import { Typography } from 'components/typography'
import Image from 'next/future/image'

import styles from './loader.module.scss'

export const Loader = () => {
  return (
    <div className={styles.loader}>
      <div className={styles.loader__container}>
        <Image
          src={'/logos/glow-logo.png'}
          alt={'Tempus Logo'}
          height={132}
          width={135}
          priority
        />
        <div className={styles.loader__text}>
          <Typography variant="heading-2" className={styles.loading}>
            Loading
          </Typography>
        </div>
      </div>
    </div>
  )
}
