import * as React from 'react'
import { gsap } from 'gsap'
import { WithChildren } from 'types'
// import { TransitionContext } from '../context/TransitionContext'

type FadeInProps = WithChildren<{
  duration?: number
  delay?: number
  className?: string
}>

export const FadeIn = ({
  children,
  duration,
  delay,
  className,
}: FadeInProps) => {
  const el = React.useRef(null)

  // gsap.set(el.current, { transform: 'translate(0px, ' + (y || 40) + 'px)' })

  React.useLayoutEffect(() => {
    gsap.to(el.current, {
      opacity: 1,
      delay: delay || 0,
      duration: duration || 1,
      ease: 'back.out',
    })
  }, [])

  return (
    <div style={{ opacity: 0 }} ref={el} className={className}>
      {children}
    </div>
  )
}
